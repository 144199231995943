import React from 'react';
import Navigation from '../components/Navigation';
import moment from 'moment';
import Person from '../components/home/Person';




const Home = () => {
    require('react-datetime');
    require('moment/locale/fr');
    return (
        <div className="home">
            <Navigation/>
            <div className="homeContent">
                <div className="content">
                    <h1>Nicolas QUÉRAT</h1>
                    <h2>
                        <div className="DateTime">
                            <h2>37 Ans</h2>
                           </div> 
                    </h2>
                    <h2>Développeur Front-end</h2>
                    
                    <div className="pdf">
                        <a href="./media/CV - QUERAT Nicolas 12 - 2020.pdf" target="_blank">Télécharger CV</a>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Home;