import React from 'react';
import Navigation from '../components/Navigation';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Contact = () => {
    return (
        <div className="contact">
           <Navigation/>
           <div className="contactContent">
               <div className="header"></div>
               <div className="contactBox">
                   <h1>Contactez-moi</h1>
                   <ul>
                       <li>
                           <i className="fas fa-map-marker-alt"></i>
                           <span>La Possession</span>
                       </li>
                       <li>
                           <i className="fas fa-mobile-alt"></i>
                           <CopyToClipboard text="0692062523">
                               <span className="clickInput" onClick={() => { alert('n° de Téléphone copié !');}}>0692 06 25 23</span>
                           </CopyToClipboard>
                       </li>
                       <li>
                           <i className="fas fa-envelope"></i>
                           <CopyToClipboard text="querat.nicolas@gmail.com">
                               <span className="clickInput" onClick={() => { alert('E-mail copié !');}}>querat.nicolas@gmail.com</span>
                           </CopyToClipboard>
                       </li>
                   </ul>
               </div>
               <div className="socialNetwork">
                   <ul>
                       <a href="https://www.linkedin.com/feed/" target="_blank" rel="noopener noreferrer">
                           <h4>LinkedIn</h4>
                           <i classNam="fab fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/Nickosss97435" target="_blank" rel="noopener noreferrer">
                           <h4>Github</h4>
                           <i classNam="fab fa-guithub"></i>
                       </a>
                       <a href="https://www.facebook.com/nicolas.querat.7" target="_blank" rel="noopener noreferrer">
                           <h4>Facebook</h4>
                           <i classNam="fab fa-facebook"></i>
                       </a>
                       <a href="https://www.youtube.com/channel/UCHHm8yelVTovLydH5pOVz9A" target="_blank" rel="noopener noreferrer">
                           <h4>Youtube</h4>
                           <i classNam="fab fa-youtube"></i>
                       </a>
                   </ul>

               </div>
           </div>
        </div>
    );
};

export default Contact;