import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
        languages: [
            {id: 1, value: "Html", xp:2},
            {id: 2, value: "Css", xp:1.8},
            {id: 3, value: "Javascript", xp:0.7},
            {id: 4, value: "Python", xp:0.4},
        ],
        frameworks: [
            {id: 1, value: "React", xp:1.4},
            {id: 2, value: "Vue", xp:0.2},
            {id: 3, value: "Bootstrap", xp:0.1},
            {id: 4, value: "Sass", xp:0.1},
        ],
    }
    render() {
        let {languages, frameworks} = this.state;

        return (
            <div className="languagesFrameworks">
                <ProgressBar 
                languages={languages}
                className="languagesDisplay"
                title="languages"
                />
                <ProgressBar 
                languages={frameworks}
                title="Frameworks & Bibliothèques"
                className="frameworksDisplay"
                />

            </div>
        );
    }
}

export default Languages;