import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {

    return (
        <div className="sidebar">
            <div className="id">
                <div className="idContent">
                    <img src="./media/nicolasQuerat.jpg" alt="profil-pic"/>
                    <h3>Nicolas QUÉRAT</h3>
                </div>
            </div>

            <div className="navigation">
                <ul>
                    <li>
                    <NavLink exact to="/" activeClassName="navactive">
                      <i className="fas fa-home"></i>  
                      <span>Accueil</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink exact to="/competences" activeClassName="navactive">
                      <i className="fas fa-mountain"></i>  
                      <span>competences</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink exact to="/portfolio" activeClassName="navactive">
                      <i className="fas fa-images"></i>  
                      <span>Portfolio</span>
                    </NavLink>
                    </li>
                    <li>
                    <NavLink exact to="/contact" activeClassName="navactive">
                      <i className="fas fa-address-book"></i>  
                      <span>Contact</span>
                    </NavLink>
                    </li>
                </ul>
            </div>

            <div className="socialNetwork">
                <ul>
                    <li>
                        <a href="https://www.linkedin.com/feed/" target=" blank"rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                    </li>
                    <li>
                        <a href="https://github.com/Nickosss97435" target=" blank"rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/nicolas.querat.7" target=" blank"rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCHHm8yelVTovLydH5pOVz9A" target=" blank"rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                    </li>
                </ul>
                <div className="signature">
                <p>Quér@nim - 2021</p>
                </div>
            </div>

        </div>
    );
};

export default Navigation;