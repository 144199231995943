export const portfolioData = [
  {
    id: 1,
    name: 'caze974.dyndns.org',
    languages: ['javascript','css'],
    languagesIcons: ['fab fa-js','fab fa-css3-alt'],
    source: 'http://caze974.dyndns.org',
    info: 'Site Entreprise pour windows server.',
    picture: './media/project1.PNG'
  },
  {
    id: 2,
    name: 'walali.com',
    languages: ['javascript','react'],
    languagesIcons: ['fab fa-js','fab fa-react'],
    source: 'http://www.walali.com',
    info: 'Site eCommerce pour la sociéte WALALI.',
    picture: './media/project2.JPG'
  },

]