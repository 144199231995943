import React from 'react';

const Experience = () => {
    return (
        <div className="experience">
            <h3>Experience</h3>
            <div className="exp-1">
                <h4>Directeur Technique - WALALI</h4>
                <h5>2015 - 2020</h5>
                <li>
                <p>Organisation des équippes et des diffèrents chantiers. 
                    Assurer la rentabilité des journées de chaque équippes
                    Préparation du matèriel. Passer les commandes fournisseurs, 
                    en colabaoration avec le directeur financier
                    Gestion des stock.
                    Prise de rendez-vous clients
                    Etablire des devis, Commandes, Facture clients
                    Mise en place du réseau informatique de l'entreprise: -Serveur Windows Server 2012 DatatCenter puis Windows Server 2016
                    - mise en réseau des differents poste et du logiciel EBP Gestion commercial</p></li>
            </div>
            <div className="exp-2">
                <h4>Informatique & Télécommunication - DIRECTIQUE</h4>
                <h5>2012 - 2012</h5>
                <p>Teste sur les diffèrents reseau mobiles de la réunion, 
                    afin d'améliorer la qualité des service "Orange Réunion".</p>
            </div>
            <div className="exp-3">
                <h4>Gérant - Les 4 Saveurs Culinaires</h4>
                <h5>2011 - 2012</h5>
                <p>Gérant d'un restaurant</p>
            </div>
            <div className="exp-4">
                <h4>Technicien Informatique - Chronos Informatique</h4>
                <h5>2010 - 2011</h5>
                <p>Réparation PC windws Mac & Linux et maintenance réseau.</p>
            </div>
            
        </div>
    );
};

export default Experience;