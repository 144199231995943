import React from 'react';

const Hobbies = () => {
    return (
        <div className="hobbies">
            <h3>Intérets</h3>
            <ul>
                <li className="hobby">
                    <i className="fas fa-music">
                        <span>Musique</span>
                    </i>
                </li>
                <li className="hobby">
                    <i className="fas fa-hiking">
                        <span>Randonnées</span>
                    </i>
                </li>
                <li className="hobby">
                    <i className="fas fa-seedling">
                        <span>Plantes</span>
                    </i>
                </li>
                <li className="hobby">
                    <i className="fas fa-palette">
                        <span>Dessin</span>
                    </i>
                </li>
            </ul>
            
        </div>
    );
};

export default Hobbies;